import React from 'react';
import '../styles/index.css';


export default function MyWorks() {
  return (
    <div>
    <h1>This page is still under development.</h1>
    <p>Please check this out later</p>
    </div>
  );
}
